<template>
  <div>
    <div v-if="permissions['credores.index'].permissao">
      <!-- button -->
      <b-button
        v-if="permissions['credores.store'].permissao"
        id="toggle-btn"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-prevent-closing
        class="mb-1"
        variant="primary"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Novo Credor</span>
      </b-button>
      <!-- modal -->
      <b-modal
        id="modal-prevent-closing"
        ref="my-modal"
        size="lg"
        title="Cadastrar Novo Credor"
        ok-title="Cadastrar"
        cancel-title="Cancelar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form>
            <b-row>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  label="Nome"
                  label-for="name-input"
                >
                  <b-form-input
                    id="name-input"
                    v-model="name"
                    placeholder="Nome"
                    :state="nameState"
                    required
                  />
                  <b-form-invalid-feedback :state="nameState">
                    Nome é obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group>
                  <label for="rasao-social">Razão Social</label>
                  <b-form-input
                    id="rasao-social"
                    v-model="razaoSocial"
                    type="text"
                    :state="razaoSocialState"
                    placeholder="Razão Social"
                  />
                  <b-form-invalid-feedback :state="razaoSocialState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  label="Alias Completo"
                  label-for="alias_completo"
                >
                  <b-form-input
                    id="alias_completo"
                    v-model="aliasCompleto"
                    placeholder="Alias Completo"
                    :state="aliasCompletoState"
                  />
                  <b-form-invalid-feedback :state="aliasCompletoState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  label="Alias Abreviado"
                  label-for="alias_abreviado"
                >
                  <b-form-input
                    id="alias_abreviado"
                    v-model="aliasAbreviado"
                    placeholder="Alias Abreviado"
                    :state="aliasAbreviadoState"
                  />
                  <b-form-invalid-feedback :state="aliasAbreviadoState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  label="Inscrição Estadual"
                  label-for="Inscricao_estadual"
                >
                  <b-form-input
                    id="inscricao_estadual"
                    v-model="inscricaoEstadual"
                    placeholder="Inscrição Estadual"
                    :state="inscricaoEstadualState"
                  />
                  <b-form-invalid-feedback :state="inscricaoEstadualState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group>
                  <label>Grupo</label>
                  <v-select
                    v-model="grupo"
                    :options="grupoOpcoes"
                  >
                    <template #item="selectedOption">
                      <div
                        v-if="selectedOption.label === 'CADASTRAR NOVO CREDOR'"
                        @click="showModalCadastroRede = true"
                      >
                        {{ selectedOption.label }}
                      </div>
                      <div v-else>
                        {{ selectedOption.label }}
                      </div>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback :state="grupoState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-button
                  variant="primary"
                  @click="openModal"
                >
                  Casdatrar novo Grupo
                </b-button>
                <b-modal
                  ref="modalCadastroRede"
                  size="lg"
                  hide-footer
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="mt-1"
                    >
                      Nome :
                      <b-form-input
                        v-model="template.nome"
                        placeholder="nome"
                      />
                    </b-col>
                  </b-row>
                  <hr>
                  <b-row
                    v-if="novoTemplate"
                    align-h="end"
                    class="mt-1"
                  >
                    <b-col cols="auto">
                      <b-button
                        variant="danger"
                        @click="$refs.modalCadastroRede.hide()"
                      >
                        Cancelar
                      </b-button>
                    </b-col>
                    <b-col cols="auto">
                      <b-button
                        variant="primary"
                        @click="storeGrupos"
                      >
                        Cadastrar
                      </b-button>
                    </b-col>
                  </b-row>
                  <!-- novoTemplate = false INICIO DO MODAL COM BOTAO SALVAR DO EDIT -->
                  <b-row
                    v-if="!novoTemplate"
                    align-h="end"
                    class="mt-1"
                  >
                    <b-col
                      cols="auto"
                    >
                      <b-button
                        variant="danger"
                        @click="$refs.modalCadastroRede.hide()"
                      >
                        Cancelar
                      </b-button>
                    </b-col>
                    <b-col
                      cols="auto"
                    >
                      <b-button
                        variant="primary"
                        @click="updateTemplateGrupo"
                      >
                        <feather-icon
                          icon="SaveIcon"
                        />
                        Salvar
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <b-card>
                        <b-row align-h="center">
                          <b-col cols="8">
                            <b-form-input
                              v-model="tableTemplateGrupos.filter"
                              type="search"
                              placeholder="Pesquisar"
                              class="mb-2"
                              size="sm"
                            />
                          </b-col>
                        </b-row>
                        <b-table
                          responsive
                          hover
                          bordered
                          striped
                          :filter="tableTemplateGrupos.filter"
                          :current-page="tableTemplateGrupos.currentPage"
                          :per-page="tableTemplateGrupos.perPage"
                          :fields="tableTemplateGrupos.fields"
                          :items="tableTemplateGrupos.items"
                        >
                          <template #cell(detalhes)="data">
                            <b-row align-h="between">
                              <b-col>
                                <feather-icon
                                  class="color-icon"
                                  icon="EditIcon"
                                  @click="rowClicked(data.item)"
                                />
                              </b-col>
                              <b-col>
                                <feather-icon
                                  class="trash-icon"
                                  icon="TrashIcon"
                                  @click="deleteGrupos(data.item)"
                                />
                              </b-col>
                            </b-row>
                          </template>
                        </b-table>
                        <b-pagination
                          v-model="tableTemplateGrupos.currentPage"
                          :total-rows="tableTemplateGrupos.totalRows"
                          :per-page="tableTemplateGrupos.perPage"
                          align="center"
                          size="sm"
                        />
                      </b-card>
                    </b-col>
                  </b-row>
                </b-modal>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  label="CNPJ"
                  label-for="cnpj"
                  :state="cnpjState"
                >
                  <cleave
                    id="customDelimiter"
                    v-model="cnpj"
                    class="form-control"
                    :raw="false"
                    :options="mask.cnpj"
                    placeholder="00.000.000/0000-00"
                  />
                  <b-form-invalid-feedback :state="cnpjState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                sm="12"
              >
                <b-form-group
                  label="CEP"
                  label-for="cep"
                  :state="cepState"
                >
                  <b-input-group>
                    <cleave
                      v-model="cep"
                      name="cep"
                      class="form-control"
                      :raw="false"
                      :options="mask.cep"
                      placeholder="00000-000"
                      @keyup.enter="consultaCEP()"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="consultaCEP()"
                      >
                        <feather-icon icon="SearchIcon" />
                      </b-button>
                      <b-form-invalid-feedback :state="cepState">
                        Campo obrigatório
                      </b-form-invalid-feedback>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                md="7"
                sm="12"
              >
                <b-form-group>
                  <label for="rua">Rua</label>
                  <b-form-input
                    id="rua"
                    v-model="rua"
                    type="text"
                    :state="ruaState"
                    placeholder="rua"
                  />
                  <b-form-invalid-feedback :state="ruaState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                sm="12"
              >
                <b-form-group>
                  <label for="numero">Número</label>
                  <b-form-input
                    id="numero"
                    v-model="numero"
                    type="text"
                    placeholder="número"
                    :state="numeroState"
                  />
                  <b-form-invalid-feedback :state="numeroState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group>
                  <label for="bairro">Bairro</label>
                  <b-form-input
                    id="bairro"
                    v-model="bairro"
                    type="text"
                    placeholder="bairro"
                    :state="bairroState"
                  />
                  <b-form-invalid-feedback :state="bairroState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group>
                  <label>Cidade</label>
                  <v-select
                    v-model="cidade"
                    :options="cidadesOpcoes"
                  />
                  <b-form-invalid-feedback :state="cidadeState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
                cols="2"
              >
                <b-form-group
                  label="Telefone"
                  label-for="telefone"
                  :state="telefoneState"
                >
                  <cleave
                    id="telefone"
                    v-model="telefone"
                    class="form-control"
                    :raw="false"
                    :options="mask.telefone"
                    placeholder="(99) 9999-9999"
                  />
                  <b-form-invalid-feedback :state="telefoneState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group>
                  <label for="email">Email</label>
                  <b-form-input
                    id="email"
                    v-model="email"
                    type="email"
                    :state="emailState"
                    placeholder="Email"
                  />
                  <b-form-invalid-feedback :state="emailState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
                hidden
              >
                <b-form-group
                  label="Status em Processo"
                  label-for="status-em-processo"
                >
                  <b-form-input
                    id="status_em_processo"
                    v-model="statusemProcesso"
                    placeholder="Status em Processo"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
                hidden
              >
                <b-form-group
                  label="Status nao Localizado"
                  label-for="status-nao-localizado"
                >
                  <b-form-input
                    id="status_nao_localizado"
                    v-model="statusnaoLocalizado"
                    placeholder="Status não Localizado"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="3"
                sm="12"
              >
                <b-form-group
                  label="Status"
                  label-for="status"
                >
                  <v-select
                    v-model="selected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="option"
                  />
                  <b-form-invalid-feedback :state="selectedState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                sm="12"
              >
                <b-form-group
                  label="Recebimentos"
                  label-for="recebimentos"
                >
                  <v-select
                    v-model="recebimento"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="options"
                  />
                  <b-form-invalid-feedback :state="recebimentoState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                sm="12"
              >
                <b-form-group
                  label="Tipo de Remessa"
                  label-for="tipoRemessa"
                >
                  <v-select
                    v-model="tipoRemessa"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="optionsTipoRemessa"
                  />
                  <b-form-invalid-feedback :state="tipoRemessaState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                sm="12"
              >
                <b-form-group
                  label="Dias Extração"
                  label-for="diasExtracao"
                >
                  <v-select
                    v-model="diasExtracao"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="optionsDiasExtracao"
                    multiple
                  />
                  <b-form-invalid-feedback :state="diasExtracaoState">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="3"
                sm="12"
              >
                <label>Prazo Remessa</label>
                <b-form-input
                  v-model="prazoRemessa"
                  :state="prazoRemessaState"
                  type="number"
                />
                <b-form-invalid-feedback :state="prazoRemessaState">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-col>
              <b-col
                md="3"
                sm="12"
              >
                <label>Prazo Honorário</label>
                <b-form-input
                  v-model="prazoHonorario"
                  :state="prazoHonorarioState"
                  type="number"
                />
                <b-form-invalid-feedback :state="prazoHonorarioState">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <label>Tipo de Credor</label>
                <v-select
                v-model="tipoCredor"
                placeholder="Tipo do credor"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="optionsTipoCredor"
              />
              </b-col>
              <b-col class="mt-1">
                <label>Negativação</label>
                <b-form-checkbox v-model="negativacao" />
              </b-col>
              <b-col class="mt-1">
                <label>Gerar Número Operação</label>
                <b-form-checkbox v-model="gerarNumero" />
              </b-col>
            </b-row>
          </b-form>
        </form>
      </b-modal>
      <b-card>
        <!-- <div v-if="load">
            <b-row align-h="center">
              <b-col
                cols="auto"
                class="d-fle"
              >
                <b-spinner style="width: 10rem; height: 10rem;" />
              </b-col>
            </b-row>
          </div> -->
        <b-overlay
          :show="load"
          variant="transparent"
        >
          <b-row>
            <b-col cols="12">
              <b-row align-h="between">
                <b-col
                  md="2"
                  sm="4"
                  class="my-1"
                >
                  <b-form-group class="mb-0">
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      class="w-50"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="8"
                  class="my-1"
                >
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    size="sm"
                    placeholder="Pesquisar"
                  />
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(nome)="data">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :text="getInitials(data.item.nome)"
                        :style="{ backgroundColor: randomColor(data.item.id) }"
                        :to="{
                          name: 'credor-view',
                          params: { id: data.item.id },
                        }"
                      />
                    </template>
                    <b-link
                      :to="{
                        name: 'credor-view',
                        params: { id: data.item.id },
                      }"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ data.item.nome }}
                    </b-link>
                  </b-media>
                </template>

                <template #cell(cnpj)="data">
                  {{ data.item.cnpj }}
                </template>

                <template #cell(status)="data">
                  <b-badge :variant="status[1][data.value]">
                    {{ status[0][data.value] }}
                  </b-badge>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <!-- <feather-icon
                        :id="`view-${data.item.id}`"
                        icon="EditIcon"
                        size="16"
                        class="mx-1"
                        @click="$router.push({ name: 'credor-edit', params: { id: data.item.id }})"
                      /> -->
                    <feather-icon
                      v-if="permissions['credores.delete'].permissao"
                      :id="`delete-${data.item.id}`"
                      class="trash-icon"
                      icon="TrashIcon"
                      size="16"
                      @click="deletCredor(data.item.id, data.item.nome)"
                    />
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BForm,
  BAvatar,
  BMedia,
  BLink,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BOverlay,
  BModal,
  VBModal,
  BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import notAuthorized from '@/views/misc/notAuthorized.vue'
import axios from '../../../../axios-auth'

export default {
  components: {
    BTable,
    BForm,
    BAvatar,
    BMedia,
    BLink,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BModal,
    Cleave,
    BOverlay,
    vSelect,
    notAuthorized,
    BFormCheckbox,
    BFormInvalidFeedback,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      novoTemplate: true,
      tableTemplateGrupos: {
        filter: '',
        fields: [
          'nome',
          { key: 'detalhes', label: 'ações', class: 'text-center' },
        ],
        items: [],
        totalRows: 1,
        perPage: 10,
        currentPage: 1,
      },
      template: {
        nome: '',
      },
      grupoOpcoes: [],
      showModalCadastroRede: false,
      grupo: [],
      dt: {},
      cidadesOpcoes: [],
      permissions: null,
      load: true,
      ativo: null,
      nome: '',
      razaoSocial: '',
      inscricaoEstadual: '',
      aliasCompleto: '',
      aliasAbreviado: '',
      name: '',
      cnpj: '',
      cep: '',
      rua: '',
      numero: '',
      bairro: '',
      cidade: '',
      telefone: '',
      email: '',
      statusemProcesso: '',
      statusnaoLocalizado: '',
      tipoCredor: '',
      prazoRemessa: null,
      prazoHonorario: null,
      diasExtracao: null,
      negativacao: false,
      gerarNumero: false,
      submittedNames: [],
      selected: { title: '' },
      recebimento: null,
      tipoRemessa: null,
      option: [{ title: 'Ativo' }, { title: 'Inativo' }],
      options: [{ title: 'Conta Voz' }, { title: 'Conta Credor' }],
      optionsTipoRemessa: [{ title: 'ENVIADA' }, { title: 'EXTRAÍDA' }],
      optionsDiasExtracao: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      optionsTipoCredor: [{ label: 'ESCOLA', value: 'ESCOLA' }, { label: 'FACULDADE', value: 'FACULDADE' }, { label: 'PÓS-GRADUAÇÃO', value: 'POS GRADUACAO' }],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      form: {
        cardNumber: null,
        date: null,
        phone: null,
      },
      mask: {
        cep: {
          delimiters: ['-'],
          blocks: [5, 3],
        },
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        telefone: {
          delimiters: [' ', '-', '-'],
          blocks: [2, 4, 4],
          uppercase: true,
        },
      },
      fields: [
        { key: 'id', label: 'id' },
        {
          key: 'nome',
          label: 'credores',
          sortable: true,
        },
        { key: 'cnpj', label: 'cnpj', sortable: true },
        { key: 'status', label: 'status', sortable: true },
        { key: 'actions', label: 'ações' },
      ],
      colorCache: {},
      items: [],
      status: [
        {
          1: 'Current',
          2: 'Ativo',
          3: 'Rejected',
          4: 'Inativo',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    grupoState() {
      if (this.grupo) {
        return true
      }
      return false
    },
    nameState() {
      if (this.name) {
        return true
      }
      return false
    },
    razaoSocialState() {
      if (this.razaoSocial) {
        return true
      }
      return false
    },
    inscricaoEstadualState() {
      if (this.inscricaoEstadual) {
        return true
      }
      return false
    },
    aliasCompletoState() {
      if (this.aliasCompleto) {
        return true
      }
      return false
    },
    aliasAbreviadoState() {
      if (this.aliasAbreviado) {
        return true
      }
      return false
    },
    cnpjState() {
      if (this.cnpj) {
        return true
      }
      return false
    },
    cepState() {
      if (this.cep) {
        return true
      }
      return false
    },
    ruaState() {
      if (this.rua) {
        return true
      }
      return false
    },
    numeroState() {
      if (this.numero) {
        return true
      }
      return false
    },
    bairroState() {
      if (this.bairro) {
        return true
      }
      return false
    },
    cidadeState() {
      if (this.cidade) {
        return true
      }
      return false
    },
    telefoneState() {
      if (this.telefone) {
        return true
      }
      return false
    },
    emailState() {
      if (this.email) {
        return true
      }
      return false
    },
    selectedState() {
      if (this.selected) {
        return true
      }
      return false
    },
    recebimentoState() {
      if (this.recebimento) {
        return true
      }
      return false
    },
    prazoRemessaState() {
      if (this.prazoRemessa) {
        return true
      }
      return false
    },
    tipoRemessaState() {
      if (this.tipoRemessa) {
        return true
      }
      return false
    },
    diasExtracaoState() {
      if (this.diasExtracao) {
        return true
      }
      return false
    },
    prazoHonorarioState() {
      if (this.prazoHonorario) {
        return true
      }
      return false
    },
  },
  async created() {
    await this.updateList()
    this.permissions = JSON.parse(localStorage.permissoes).credores
    this.listarCidades()
    await this.getGrupos()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    closeModal() {
      this.id = null
      this.template = {}
      this.edit = false
      this.novoTemplate = false
    },
    openModal() {
      this.$refs.modalCadastroRede.show()
      this.novoTemplate = true
    },
    clearCamposTemplate() {
      this.template = {
        id: '',
        nome: '',
      }
    },
    storeGrupos() {
      axios.post('api/v1/rede/store', this.template, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.$refs.modalCadastroRede.hide()
          this.getGrupos()
          this.$swal({
            title: 'Grupo cadastrado com sucesso',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.getGrupos()
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
      this.clearCamposTemplate()
    },
    async getGrupos() {
      this.tableTemplateGrupos.items = []
      this.grupoOpcoes = []
      await axios
        .get('api/v1/rede/', {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        })
        .then(res => {
          res.data.dados.map(grupo => {
            this.tableTemplateGrupos.items.push({
              id: grupo.id,
              nome: grupo.nome.toUpperCase(),
            })
          })
          res.data.dados.map(grupo => {
            this.grupoOpcoes.push({
              label: grupo.nome.toUpperCase(),
              id: grupo.id,
            })
          })
          this.tableTemplateGrupos.totalRows = this.tableTemplateGrupos.items.length
        })
    },
    deleteGrupos(item) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar o grupo ${item.nome}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/rede/delete/${item.id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(res => {
              if (res.data) {
                this.$refs.modalCadastroRede.hide()
                this.getGrupos()
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Nome deletado com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              }
            })
            .catch(error => {
              if (error.response) {
                this.$swal({
                  title: 'Erro ao deletar Nome!',
                  text: error.response.data.nome,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
              } else {
                // Something happened in setting up the request that triggered an Error
              }
            })
        }
      })
    },
    rowClicked(item) {
      this.id = item.id
      axios.get(`api/v1/rede/edit/${item.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.template = res.data.dados
        })
      this.edit = true
      this.novoTemplate = false
      this.$refs.modalCadastroRede.show()
    },
    async updateTemplateGrupo() {
      const body = this.template
      await axios.put(`api/v1/rede/update/${this.id}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/jason',
        },
      }).then(() => {
        this.$refs.modalCadastroRede.hide()
        this.clearCamposTemplate()
        this.getGrupos()
        this.$swal({
          icon: 'success',
          title: 'Salvo!',
          text: 'Grupo salvo com sucesso!',
          timer: 1500,
          showConfirmButton: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch(error => {
        this.$swal({
          title: 'Erro ao Salvar!',
          text: error,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    async consultaCEP() {
      if (this.cep) {
        if (this.cep.replace(/[^0-9]/g, '').length === 8) {
          let info = {
            cep: '',
            cidade: {},
            rua: '',
            uf: '',
            bairro: '',
          }
          await axios
            .get(`https://viacep.com.br/ws/${this.cep}/json/`)
            .then(async res => {
              const { data } = res
              if (data.erro) {
                this.$swal({
                  title: 'ERROR',
                  text: 'CEP não encontrado',
                  icon: 'error',
                  customClass: {
                    comfirmButton: 'btn btn-danger',
                  },
                })
              } else {
                info = {
                  cep: data.cep.replace(/[^0-9]/g, ''),
                  cidade: {
                    id: null,
                    nome: this.removerAcentos(data.localidade),
                  },
                  rua: data.logradouro.toUpperCase(),
                  bairro: data.bairro.toUpperCase(),
                }
                await axios
                  .post(
                    'api/v1/cidades/filtro',
                    {
                      nome: info.cidade.nome.toUpperCase(),
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.token}`,
                      },
                    },
                  )
                  .then(response => {
                    const { cidade } = response.data.dados
                    if (cidade) {
                      cidade.filter(e => e.nome.toUpperCase() === info.cidade)
                    }
                    if (cidade.length > 0) {
                      info.cidade.id = cidade[0].id
                    }
                    const keys = Object.keys(info)
                    keys.map(key => {
                      this.dt[key] = info[key]
                    })
                  })
                this.rua = info.rua
                this.bairro = info.bairro
                this.cidade = {
                  id: info.cidade.id,
                  label: info.cidade.nome,
                }
              }
            })
            .catch(() => {
              this.$swal({
                title: 'ERROR',
                text: 'CEP não encontrado',
                icon: 'error',
                customClass: {
                  comfirmButton: 'btn btn-danger',
                },
              })
            })
        }
      }
      return null
    },
    async listarCidades() {
      this.cidadesOpcoes = []
      await axios
        .get('api/v1/cidades', {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        })
        .then(res => {
          res.data.dados.map(cidade => {
            this.cidadesOpcoes.push({
              label: cidade.nome.toUpperCase(),
              id: cidade.id,
            })
          })
        })
    },
    deletCredor(id, nome) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar o credor ${nome}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(`api/v1/credores/delete/${id}`, {
              headers: {
                Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                accept: 'application/json',
              },
            })
            .then(res => {
              if (res.data) {
                this.updateList()
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Credor deletado com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => {
              // Error
              if (error.response) {
                this.$swal({
                  title: 'Erro ao deletar Credor!',
                  text: error.response.data.mensagem,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
              } else {
                // Something happened in setting up the request that triggered an Error
              }
            })
        }
      })
    },
    async updateList() {
      this.load = true
      await axios
        .get('api/v1/credores/', {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        })
        .then(res => {
          const credores = []
          const objeto = res.data.dados
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const prop in objeto) {
            if (objeto[prop].status) {
              this.ativo = 2
            } else {
              this.ativo = 4
            }
            credores.unshift({
              id: objeto[prop].id,
              avata: '',
              nome: objeto[prop].nome,
              cnpj: this.cpfCNPJ(objeto[prop].cnpj),
              status: this.ativo,
            })
          }
          this.totalRows = credores.length
          this.items = credores
          this.load = false
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // checkFormValidity() {
    //   const valid = this.$refs.form.checkValidity()
    //   this.nameState = valid
    //   return valid
    // },
    resetModal() {
      this.rede_id = null
      this.name = null
      this.nameState = null
      this.cnpj = null
      this.inscricaoEstadual = null
      this.aliasCompleto = null
      this.aliasAbreviado = null
      this.razaoSocial = null
      this.selected = null
      this.recebimento = null
      this.tipoRemessa = null
      this.diasExtracao = null
      this.email = null
      this.statusemProcesso = null
      this.statusnaoLocalizado = null
      this.cep = null
      this.rua = null
      this.bairro = null
      this.telefone = null
      this.cidade = null
      this.status = null
      this.prazoRemessa = null
      this.prazoHonorario = null
      this.negativacao = false
      this.gerarNumero = false
      this.tipoCredor = ''
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return
      // }
      const formData = {
        rede_id: this.grupo.id,
        nome: this.name,
        razao_social: this.razaoSocial,
        cnpj: this.cnpj.replace(/[^0-9]/g, ''),
        inscricao_estadual: this.inscricaoEstadual.replace(/[^0-9]/g, ''),
        alias_completo: this.aliasCompleto,
        alias_abreviado: this.aliasAbreviado,
        cep: this.cep,
        endereco: this.rua,
        bairro: this.bairro,
        numero: this.numero,
        telefone: this.telefone.replace(/[^0-9]/g, ''),
        email: this.email,
        status_em_processo: this.statusemProcesso,
        status_nao_localizado: this.statusnaoLocalizado,
        cidade_id: this.cidade.id,
        status: true,
        recebimentos: this.recebimento.title,
        tipo_remessa: this.tipoRemessa.title,
        dias_extracao: JSON.stringify(this.diasExtracao),
        empresa_id: 1,
        prazo_remessa: this.prazoRemessa,
        prazo_honorario: this.prazoHonorario,
        negativacao: this.negativacao,
        gerar_numero_operacao: this.gerarNumero,
        tipo_credor: this.tipoCredor ? this.tipoCredor.value : null,
      }
      await axios
        .post('api/v1/credores/store', formData, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        })
        .then(res => {
          if (res.data) {
            this.updateList()
            this.$swal({
              title: 'Credor cadastrado com sucesso',
              icon: 'success',
              timer: 1500,
              showConfirmButton: false,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$refs['my-modal'].toggle('#toggle-btn')
          }
        })
        .catch(error => {
          // Error
          if (error.response) {
            this.$swal({
              title: 'Erro ao cadastrar!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
